@charset "UTF-8";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 30px;
  color: #646464;
  font-weight: 400;
  height: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow: inherit;
  overflow-x: hidden;
} */
.NipponLogo
{
width: 50px;
margin: 10px;
}
/*--- Buttons ---*/
.btn-fill {
  font-weight: 500;
  font-size: 16px;
  position: relative;
  z-index: 2;
  padding: 10px 27px;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* background-color: #5a49f8;
  border-color: #5a49f8; */
  background-color: #646464;
  border-color: #646464;
  border: 2px solid;
 
  color: #ffffff;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.btn-fill i {
  margin-top: 2px;
  line-height: 1;
  margin-left: 12px;
  -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
          transform: translateX(0px);
  color: #ffffff;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}


.btn-fill:hover i {
  color: #5a49f8;
  -webkit-transform: translateX(5px);
      -ms-transform: translateX(5px);
          transform: translateX(5px);
}


.btn-ghost.btn-dark:hover {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #1d2124;
}

.btn-fill.btn-gradient {
  border: 0;
  padding: 12px 28px;
}


.btn-fill.btn-gradient:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: inherit;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 4px;
  /* background: #5a49f8;
  background: -o-linear-gradient(205deg, #5a49f8 40%, #7a64f2 100%);
  background: linear-gradient(245deg, #5a49f8 40%, #7a64f2 100%); */

  background: gray;
  background: -o-linear-gradient(205deg, gray 40%, gray 100%);
  background: linear-gradient(245deg, gray 40%, gray 100%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.btn-fill.btn-gradient:hover {
  color: #ffffff;
}

.btn-fill.btn-gradient:hover i {
  color: #ffffff;
}

.btn-fill.btn-gradient:hover:after {
  opacity: 0;
  visibility: hidden;
}

.btn-fill.btn-gradient:hover:before {
  opacity: 1;
  visibility: visible;
}

.btn-fill.btn-gradient2 {
  border: 0;
  padding: 12px 35px;
}


.btn-play-layout1:hover {
  /* background-color: #5a49f8; */
  background-color: gray;
  color: #ffffff;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}



.main-banner-wrap-layout1 {
  padding: 0px 0 120px;
  position: relative;
  z-index: 0;
  height: 100vh;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout1 {
    height: 100%;
    padding: 55px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout1 {
    padding: 50px 0 90px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout1 {
    padding: 45px 0 75px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout1 .animated-figure {
    display: none;
  }
}

.main-banner-wrap-layout1 .animated-figure li {
  position: absolute;
  z-index: -1;
}

.main-banner-wrap-layout1 .animated-figure li:first-child {
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 10px;
  right: inherit;
}

.main-banner-wrap-layout1 .animated-figure li:last-child {
  top: 0;
  right: 0;
  left: inherit;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.main-banner-wrap-layout1 .compress-left-side {
  padding-right: 50px;
  padding-left: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout1 .compress-left-side {
    padding-right: 0;
    padding-left: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout1 .compress-left-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout1 .compress-left-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout1 .compress-left-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.main-banner-wrap-layout1 .compress-right-side {
  padding-left: 50px;
  padding-right: calc(calc(100% - 1170px)/2);
}
@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout1 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout1 .compress-right-side {
    padding-left: calc(calc(100% - 690px)/2);
    padding-right: calc(calc(100% - 690px)/2);
  }
}
@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout1 .compress-right-side {
    padding-left: calc(calc(100% - 510px)/2);
    padding-right: calc(calc(100% - 510px)/2);
  }
}
@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout1 .compress-right-side {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.main-banner-box-layout1 .item-figure {
  margin-top: 50px;
  -webkit-animation: up_down 15s infinite;
          animation: up_down 15s infinite;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@keyframes up_down {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  25% {
    -webkit-transform: translateY(-30px) translateX(0);
            transform: translateY(-30px) translateX(0);
  }
  75% {
    -webkit-transform: translateY(30px) translateX(0);
            transform: translateY(30px) translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout1 .item-figure {
    -webkit-animation: inherit;
            animation: inherit;
  }
}
.bg-gradient-layout1 {
  position: relative;
  z-index: 0;
}
.bg-gradient-layout1:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: -o-linear-gradient(30deg, #e7e7e7 0%, #94899e 100%);
  background-image: linear-gradient(60deg, #e7e7e7 0%, #94899e 100%);
}
.tex
{
background-color: rgba(128, 128, 128, 0.988);
}

.LoginBox{
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 26px;
    background: #f7f7f7;
    border-radius: 10px;
}

/* Loader */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.css-1ji7omv-MuiBackdrop-root{
  top: 93px !important;
    left: 271px !important;
}

.MuiBackdrop-root {
  top: 93px !important;
  left: 271px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: none !important;
  font-size: 14px !important;
  color: #004f9f !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #004f9f !important;
  font-weight: bold !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #004f9f !important;
}

.f-10 {
  font-size: 10px !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.anticon-plus, .anticon-minus {
  position: relative;
  top: -4px;
}

.ant-space-horizontal {
  margin-bottom: 5px !important;
}
/* Loader End */

/* Grid & Typography */
.customRow {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.custom-grid-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.react-date-picker__wrapper {
  border-radius: 5px;
  border-width: 1px;
}

@media (max-width: 320.99px) {
  .posi_margin_top_mobile {
    margin-top: 186px !important;
  }
  .report-mt{
    margin-top: -146px !important;
  }
}

@media (min-width: 321px) and (max-width: 376px) {
  .posi_margin_top_mobile {
    margin-top: 180px !important;
}
  .report-mt{
    margin-top: -140px !important;
  }
}

@media (min-width: 377px) and (max-width: 431px) {
  .posi_margin_top_mobile {
    margin-top: 180px !important;
}
  .report-mt{
    margin-top: -140px !important;
  }
}

@media (max-width: 431px) {
  .css-1ji7omv-MuiBackdrop-root, .MuiBackdrop-root {
    top: 11.9rem !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }
  .posi_mobile_header {
    height: 116px !important;
}
}

@media (min-width: 432px) and (max-width: 768px) {
  .css-1ji7omv-MuiBackdrop-root, .MuiBackdrop-root {
    top: 11.9rem !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }
  .posi_mobile_header {
    height: 116px !important;
}
}