.label-plus-select{
    position: relative;
    display: flex;
}
.label-plus-arrow{
  display: flex;
  align-items: center;
}
.searchBox-main-div{
    position: absolute;
    top: 30px;
    z-index: 999;
}

.suggestions-list {
    width: 100%;
    /* border: 1px solid #ccc; */
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background: #fff;
    z-index: 10;
    max-height: 150px;
    overflow-y: auto;
    margin:2px 0  0  0;
    padding: 0;
    list-style: none;
}
.suggestions-list li.active {
  background-color: #f0f0f0;/* Highlight color for the active item */
}

.suggestion-item {
    padding:6px;
    cursor: pointer;
}

  
.suggestion-item:hover,
.suggestion-item[aria-selected="true"] {
    background-color: #f0f0f0;
}

.select-arrow{
    color: #004f9f;
}
.search-wrapper{
  background-color: white;
  position: absolute;

}
.filter-input{
  border:1px solid #004f9f;
  border-radius: 5px 5px 0 0;
}

.filter-input:focus{
  outline: 2px solid #004f9f;;
}
.selected-label{
  cursor: pointer;
  display: contents;
}
.label-name{
  padding: 0 4px;
}