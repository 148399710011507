.blue-color {
    color: #004f9f;
  }
  .white-color {
    color: white;
  }
  .gray-color {
    color: #7a8185;
  }
  .task-card {
    background-color: #e8e368;
    background-image: linear-gradient(to bottom right, #e20a15, #9b1932);
    border-radius: 10px;
    color: white;
    padding: 7.5px 15px;
  }
  .warning-task {
    background-color: #e8e368;
    background-image: linear-gradient(to bottom right, #e8e368, #e6b84e);
    border-radius: 10px;
    color: white;
    padding: 7.5px 15px;
  }
  .task-card-flex {
    display: flex;
    align-items: center;
  }
  .task-image {
    width: 65px;
  }
  .faults-container {
    padding-right: 7.5px !important;
  }
  .warnings-container {
    padding-left: 7.5px !important;
  }
  
  .row2 {
    margin-left: -30px;
  }
  
  .faults-card-col {
    border-radius: 10px;
    border: 1px solid #a7a9ac;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
  .fault-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faults-summary-image {
    width: 65px;
  }
  .f-s-right {
    width: 100%;
    text-align: right;
    opacity: 1;
  }
  .f-s-count {
    font: normal normal bold 28px/30px Inter;
  }
  .faults-label-name {
    color: #004f9f;
    text-align: right;
    font-size: 12px;
    font-weight: 600;
  }
  .align-end {
    text-align: end;
  }
  .count-label {
    font-size: 12px;
    font-weight: 600;
    margin-top: 7.5px;
  }
  .font15 {
    font-size: 15px;
  }
  .font40 {
    font-size: 40px;
    font-weight: bold;
  }
  .font30 {
    font-size: 30px;
    font-weight: bold;
  }
  .org-summary-img {
    width: 40px;
  }
  .org-summary-count {
    font: normal normal bold 28px/30px Inter;
  }
  .d-range-label {
    font-size: 12px;
    font-weight: bold;
    padding-right: 1.5rem;
  }
  .d-range-value {
    font-size: 12px;
    font-weight: 500;
  }
  .circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
  .yellow {
    background-color: #e8e368;
  }
  .red {
    background-color: #e20a15;
    margin-bottom: 7px;
  }
  
  @media only screen and (min-width: 1900px) {
    .task-card {
      padding: 10px 20px;
    }
    .task-image {
      width: 120px;
    }
  
    .faults-card-col {
      padding: 10px 20px;
    }
  
    .faults-summary-image {
      width: 100px;
    }
  }
  